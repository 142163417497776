<template>
	<div>
		<b-row>
			<b-col lg="6" md="6" sm="6" class="heading-title">
				<h4>Taxation</h4>
				<b-breadcrumb class="p-0 m-0" id="breadcrumb-heading">
					<b-breadcrumb-item to="/dashboard" custom> Home </b-breadcrumb-item>
					<b-breadcrumb-item active>Taxation </b-breadcrumb-item>
				</b-breadcrumb>
			</b-col>
			<b-col lg="6" md="6" sm="6" class="btns-header"> 
				
			</b-col>
		</b-row>
		<br>
		<b-tabs class="finance-custom-navbar">
			
			
			<b-tab  lazy :active="set_taxation_option=='TAX'" >
				<template #title>
					<strong>GST</strong>  
				</template>
			<!-- <Gst v-if="gst_check"/>
				<div v-else class="mt-5">GST Not Activated</div>  -->
                <Gst/>
			</b-tab> 
			<b-tab lazy :active="set_taxation_option=='TDS'" >
				<template #title>
					<strong>TDS</strong>  
				</template>
				<!-- <Tds v-if="tan_check"/>
				<div v-else  class="mt-5">TAN Not Activated</div> -->
                <Tds/>
			</b-tab> 
			
			
			
		</b-tabs>
	</div>
</template>

<script>
import Gst from './tax.vue'
import Tds from './tds.vue'
import { mapState } from "vuex";
import axios from 'axios';

	export default {
		components:
		{
			Gst,
			Tds 
		},
		data: () => ({
			title: "Tax",
			gst_check:false,
			tan_check:false,
		}),
		methods:
		{
			getProfile() {
				axios.get("/admin/get_profile").then((resp) => {
					if (resp.data.status_code == 200) {
						this.gst_check=resp.data.users.gst_no==null ? false : true;
						this.tan_check=resp.data.users.tan_no==null ? false : true;
					}
				});
			},
		},
		mounted()
		{
			this.getProfile();
		},
		computed: {
    ...mapState(['set_taxation_option']),
   },
	}
</script>

 
<style>
.finance-custom-navbar .nav-link.active  {
	color: white !important;
	background-color: #499CE8 !important;
	border-bottom: 2px solid #DD1BFB !important;
	border-color: 0 !important;
	}
</style>