<template>
    <div class="card mt-4">
                          
                      <div class="card-body">
                          <div class="venue-heading">
                  <b-row>
                    <b-col
					class="d-flex align-items-stretch"
					cols="12"
					lg="3"
					md="5"
					sm="12"
					>
						<div class="w-100">
							<multiselect
							:options="vendor_option"
							placeholder="Select Vendor"
							v-model="vendor_selected"
                            track-by="name"
              label="name"
							>
							</multiselect>
						</div>
					</b-col>
                      <b-col
                      class="d-flex align-items-stretch"
                      cols="12"
                      lg="2"
                      md="4"
                      sm="12"
                      >
                          <div class="w-100">
                              <b-form-datepicker
                              id="example-datepicker"
                              v-model="start_date"
                              placeholder="Start date"
                              class="mb-2"
                              :max="end_date"
                              :date-format-options="{
                              year: 'numeric',
                              month: 'short',
                              day: '2-digit',
                              }"
                              ></b-form-datepicker>
                          </div>
                      </b-col>
                      <b-col
                      class="d-flex align-items-stretch"
                      cols="12"
                      lg="2"
                      md="4"
                      sm="12"
                      >
                          <div class="w-100">
                              <b-form-datepicker
                              id="example-datepicker2"
                              placeholder="End date"
                              :min="start_date"
                              v-model="end_date"
                              class="mb-2"
                              :date-format-options="{
                              year: 'numeric',
                              month: 'short',
                              day: '2-digit',
                              }"
                              ></b-form-datepicker>
                          </div>
                      </b-col> 
                      
                      <b-col
                      class="d-flex align-items-stretch"
                      cols="12"
                      lg="4"
                      md="12"
                      sm="12"
                      >
                          <div class=" d-flex justify-content-end button-text">
                              <div class="p-1">
                                  <b-button variant="primary" @click="applyFilter()">
                                      Apply</b-button
                                  >
                              </div>
                              <div class="p-1">
                                  <b-button variant="primary" @click="resetFilter()">
                                      Reset</b-button
                                  >
                              </div>
                              <!-- <div class="p-1">
                                  <b-button id="button_width" variant="primary" @click="AddOffline">
                                  <i class="mdi mdi-plus"></i> Add Booking</b-button
                                  >
                                  
                                  </div>
                                  
                                  <div class="p-1">
                                  <b-button
                                  id="button_width"
                                  @click="export_pdf()"
                                  variant="primary"
                                  >
                                  <i class="mdi mdi-export"></i>Export</b-button
                                  >
                              </div> -->
                          </div>
                      </b-col>
                  </b-row>
              </div>
              
              <div>
                  <b-row>
                      <b-col
                      class="d-flex align-items-stretch"
                      cols="12"
                      lg="7"
                      md="12"
                      sm="12"
                      >
                          <div
                          class="
                          w-100
                          justify-content-sm-center
                          justify-content-xl-start
                          justify-content-md-start
                          "
                          >
                              <div class="mt-2 venue-flex">
                                  <b-form-select
                                  style="max-width: 90px;"
                                  id="select_perpage"
                                  v-model="pageSize"
                                  :options="options"
                                  @change="handlePageSizeChange($event)"
                                  ></b-form-select>
                                  
                              </div>
                          </div>
                      </b-col>
                      
                      <b-col
                      class="d-flex align-items-stretch"
                      cols="12"
                      lg="5"
                      md="12"
                      sm="12"
                      >
                          <div class="w-100 p-1">
                              <b-form-input
                              v-model="search"
                              id="search"
                              type="text"
                              placeholder="Search here"
                              v-on:keyup="key_search()"
                              ></b-form-input>
                          </div>
                      </b-col>
                  </b-row>
              </div>
              <br>
                              <b-table
                  :busy="load"
                  :fields="fields"
                  :items="bookings"
                  responsive
                  hover
                  bordered
                  show-empty
                  empty-text="No Data Available"
                  class="text-center"
                  >
                      <template  #cell(full_amount)="data">
                          <span v-html="currency_check(data.item.full_amount)"></span>
                      </template>
                       <template  #cell(total_booking_price)="data">
                          <span v-html="currency_check(data.item.total_booking_price)"></span>
                      </template>
                      <template  #cell(tax_amount)="data">
                          <span v-html="currency_check(data.item.tax_amount)"></span>
                      </template>
                      <template  #cell(booking_id)="data">
                         <router-link :to="'/Finance/taxation/view_tds_list/' + data.item.inv_from+'/'+ data.item.inv_to">
                <i class="fas fa-eye fa-lg text-dark"></i>
              </router-link> &nbsp; 
  
               <i class="fas fa-check fa-lg text-danger cursor-pointer" v-if="data.item.status=='Pending'" @click="addPayment( data.item.inv_from,data.item.vendor_id,data.item.total_booking_price,data.item.Date1)"></i>
               <i class="fas fa-check fa-lg text-success " v-else></i>
                      </template>
                      
                  
                  </b-table>
                  <b-row align-v="center">
                      <b-col cols="4">
                          <span
                          >Showing <b>{{ totalRows }}</b> out of
                              <b>{{ totalData }}</b> entries.</span
                          >
                      </b-col>
                      <b-col cols="8">
                          <b-pagination
                          v-model="page"
                          :total-rows="count"
                          :per-page="pageSize"
                          @change="handlePageChange"
                          prev-text="Prev"
                          next-text="Next"
                          align="right"
                          ></b-pagination>
                      </b-col>
                  </b-row>
                          </div>
                  </div>
  </template>
  
  <script>
  import axios from 'axios';
  import Multiselect from "vue-multiselect"; 
  export default {
          name: "gst",
          
          data: () => ({
              month_id:'',
              fields: [
              //  'index',
              {
                  key: "Date",
                  label: "Date",
                  sortable: true,
                  thClass: 'text-left',
				tdClass: 'text-left',
              },
              
          
              /*  {
                  key: "billing_phone",
                  label: "Billing Phone",
                  },
                  {
                  key: "billing_email",
                  label: "Billing Email",
                  sortable: true,
              },*/
              {
                  key: "full_amount",
                  label: "Full Amount",
                  sortable: true,
                  tdClass: 'text-right',
              },
              {
                  key: "total_booking_price",
                  label: "TDS Receivable",
                  sortable: true,
                  tdClass: 'text-right',
              },
              {
                  key: "status",
                  label: "Status",
                  sortable: true,
                  tdClass: 'text-center',
              },
              
           {
                  key: "booking_id",
                  label: "Actions",
              },
           
              ],
              bookings: [],
              options: [10, 20, 30, 50],
              
              pageSize: 10,
              show: false,
              load: true,
              filter: "",
              search: "",
              CurrentPage: 1,
              sortBy: "created_at",
              
              page: 1,
              count: 0,
              totalData: "",
              totalRows: "",
              
              PerPage: "50",
              vendor_option: [],
              vendor_selected:'',
              start_date: "",
              end_date: "",
              booked_on: "",
              venue_name_value: "",
              curncy: "",
          }),
          components: { Multiselect },
          methods: {
              key_search() {
                  const value = "1";
                  this.handlePageChange(value);
              },
              handlePageChange(value) {
                  this.page = value;
                  this.load = true;
                  this.bookingListing();
              },
              applyFilter() {
                 // const value = "1";
                 // this.handlePageChange(value);
                  this.loadInvoice();
              },
              getRequestParams(page, pageSize, sortBy = "created_at") {
                  let params = {};
                  if (page) {
                      params["page"] = page - 1;
                  }
                  if (pageSize) {
                      params["size"] = pageSize;
                  }
                  params["sortBy"] = sortBy;
                  return params;
              },
              loadInvoice()
              {
                this.$store.commit("SET_TAXATION_HEADER", 'TDS');
                  axios.post("/admin/admin_vb_tds",{
                          month:this.month_id,
                          vendor_id:this.vendor_selected.value,
                          start_date:this.start_date,
                          end_date:this.end_date,
                          
                      })
                      .then((resp) => {
                          if (resp.data.status_code == 200) {
                          this.bookings = [];
                          if (resp.data.status) {
                              this.count = this.totalData = resp.data.total_records;
                              this.totalRows = resp.data.bookings.length;
                             // var vb_amount;
                           //   var tds_amount;
                             // var vb_gst_amount;
  
                              for (var i = 0; i < resp.data.bookings.length; i++) {
                                //  vb_amount = Math.round(resp.data.bookings[i].total_invoice*4/100);
                                //  vb_gst_amount = Math.round(vb_amount*18/100);
                                 // tds_amount = Math.round(vb_amount*2/100);
                                  this.bookings.push({
                                      Date: this.date_format(resp.data.bookings[i].vb_month),
                                      Date1: resp.data.bookings[i].first_date,
                                      booking_id: resp.data.bookings[i].id,
                                      vendor_id: resp.data.bookings[i].vendor_id,
                                      auto_increment: resp.data.bookings[i].auto_increment,
                                      select_month: resp.data.bookings[i].select_month,
                                      inv_from: resp.data.bookings[i].inv_from,
                                      inv_to: resp.data.bookings[i].inv_to,
                                      booking_auto_id: resp.data.bookings[i].booking_auto_id,
                                      status:resp.data.bookings[i].paid_status,
                                    
                                      full_amount:
                                      this.formatPrice(Math.round(resp.data.bookings[i].p_vb_amount)), 
                                      
                                      total_booking_price:
                                      this.formatPrice(Math.round(resp.data.bookings[i].tds_deduct)),
                                      
                                      balance_amount:
                                      resp.data.bookings[i].total_invoice,
                                      //booked_on: resp.data.bookings[i].booked_on, 
                                     
                                   });
                              }
                              this.show = false;
                              this.load = false;
                              } else {
                              this.count = this.totalData = resp.data.total_records;
                              this.totalRows = resp.data.bookings.length;
                              
                              this.show = false;
                              this.load = false;
                          }
                      }
                  })
              },
              currency_check(amt) {
                  
                return '<i class="mdi mdi-currency-inr"></i>'+amt;
                //  return '<i class="mdi '+this.curncy+'"></i>'+amt;
              },
              getProfile() {
                  axios.get("/admin/get_profile").then((resp) => {
                      if (resp.data.status_code == 200) {
                          this.curncy= resp.data.users.currencys;
                      }
                  });
              },
              formatPrice(value) {
                  var num = value ? value : 0;
              return num.toLocaleString('en-IN')
              },
              addPayment(from_date ,vendor_id,tax_amount,pay_date)
              {
                  pay_date
                  //alert(tax_amount);
                  this.$swal({
                      title: 'Are you sure to pay '+tax_amount+' ?',
                      text: "please  confirm amount!",
                      type: 'warning',
                      showCancelButton: true,
                      confirmButtonColor: '#3085d6',
                      cancelButtonColor: '#d33',
                      confirmButtonText: 'Yes, Recived'
                      }).then((result) => {
                      if (result.value) {
                          this.save_taxamount(from_date,vendor_id,tax_amount,pay_date);
                      }
                  }); 
              },
              save_taxamount(from_date,vendor_id,tax_amount,pay_date)
              {
                  axios.post("/admin/tds_payable_amount",{
                      amt:tax_amount,
                      month:pay_date,
                      vendor_id:vendor_id,
                      from_date:from_date,
                  }).then((resp) => {
                      if (resp.data.status_code == 200) {
                          this.loadInvoice();
                      }
                  });
              },
              date_format:function(date)
          {
            var d1 = new Date(date);
			var ms = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
		//	return d1.getDate() + '' + this.getDateOrdinal(d1) +' ' + ms[d1.getMonth()] + ' ' + d1.getFullYear();
			return ms[d1.getMonth()] + ' ' + d1.getFullYear();
          },
          getDateOrdinal(date) {
                  let day = date.getDate();
                  if (day > 3 && day < 21) return 'th';
                  switch (day % 10) {
                      case 1:  return "st";
                      case 2:  return "nd";
                      case 3:  return "rd";
                      default: return "th";
                  }
              },
              vendorListing() {
      axios.post("/admin/get_all_vendors_list").then((resp) => {
        if (resp.data.status_code == 200) {
          if (resp.data.status) {
            for (var i = 0; i < resp.data.vendors.length; i++) {
              this.vendor_option.push({
                value: resp.data.vendors[i].user_id,
                name:
                  resp.data.vendors[i].first_name +
                  " " +
                  resp.data.vendors[i].last_name,
              });
            }
          }
        }
      });
    },
          },
          mounted() {
              document.documentElement.scrollTop = 0;
              this.month_id = this.$route.params.id;
              this.loadInvoice();
              this.getProfile();
              this.vendorListing();
          },
          
          //
      };
  </script>
  <style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
  <style>
  
  </style>